import React from "react"
import Layout from "../components/layout"
import TeamCard from "../components/teamcard/teamcard"
import { Container, Row, Col, Table } from 'reactstrap'
import Helmet from 'react-helmet'
import UrlSafeString from 'url-safe-string'

class StateMapTemplate extends React.Component {


    render() {

        let tagGenerator = new UrlSafeString();

        let { state, teams } = this.props.pageContext;

        let cityList = [];

        let teamCards = teams.filter((team) => {
            if (team.validated === 1)
                return team
            else return null
        }).sort((a, b) => {
            if (a.name > b.name) { return 1 }
            else if (a.name < b.name) { return -1 }
            else { return 0 }
        }).map((team) => {
            if (cityList[team.city] === undefined) {
                cityList.push(team.city.trim())
            }

            
            let slug = tagGenerator.generate(team.name, team.teamId.toString()); 

            // return <div style={{ width: "100%", height: "auto", display: "inline-block", marginBottom: "15px" }}><TeamCard team={team} state={state} key={team.id}></TeamCard></div>
            return <tr>
                <td>
                    {team.icon != "https://s3.amazonaws.com/logos.fastpitchhub.com/generic_logo.jpg" && <img alt={team.name} style={{height: "25px", width: "auto"}} src={team.icon} /> }
                </td>
                <td><a href={"/softball-map/" + state.slug + "/" + slug}>{team.name}</a></td><td>{team.city}, {team.state}</td></tr>

        })

        let nearby = state.nearby.map((n) => {
            return <Col key={n.name} xs={6} sm={6} md={4} lg={2}><a href={"/softball-map/" + n.slug} style={{ whiteSpace: "nowrap" }}>{n.name}</a> </Col>;
        })


        return (
            <Layout>
                <Helmet
                    title={state.name + " Fastpitch Softball Teams"}
                    meta={[
                        { name: 'description', content: `List of all the fastpitch softball teams in ${state.name}` },
                    ]}
                />
                <div className="wrapper">
                    <Container style={{ backgroundColor: "white", padding: "20px", borderTop: "4px solid #343a40" }}>
                        <Row>
                            <Col>



                                <h3>{state.name} Fastpitch Softball Teams</h3>

                                {teams.length > 0 &&
                                    <div>
                                        <div style={{ marginBottom: "25px" }}>There are {teams.length} teams registered with us in {state.name}.  These teams come from cities like {cityList.filter((item, index) => cityList.indexOf(item) === index).join(', ')} and nearby areas.</div>
                                        <div style={{ marginBottom: "25px" }}>Contact information for each team is included in case you need to get in touch with the coach to schedule a tryout.  If you want to see the teams on a map, check out our <a href="/softball-map">Softball Map</a></div>
                                        <div style={{ marginBottom: "25px" }}>If you are a coach and want to see your team in this list or on our <a href="/softball-map">Softball Map</a>, you can do that by clicking the "Manage" button at the top of your page  </div>
                                    </div>
                                }
                                {teams.length === 0 &&
                                    <div>
                                        <div style={{ marginBottom: "25px" }}>We don't have any teams listed in {state.name} quite yet.  Make your team the first!</div>
                                        <div style={{ marginBottom: "25px" }}>Your team's contact information and team information will then be available here and on our on our <a href="/softball-map">Softball Map</a>.</div>
                                        <div style={{ marginBottom: "25px" }}>If you want to list your team in {state.name}, you can do that <a href="/softball-map">here</a>  </div>
                                    </div>
                                }



                            </Col>
                        </Row>
                    </Container>

                    <Container style={{ padding: "0", backgroundColor: "white" }}>

                        <Table>
                            <thead><tr><th></th><th>Team Name</th><th>Team Location</th></tr></thead>
                        {teamCards}
                        </Table>
                        


                    </Container>

                    <Container style={{ backgroundColor: "white", padding: "20px", borderTop: "4px solid #343a40" }}>
                        <h3>Nearby States</h3>
                        <Row>
                            {nearby}
                        </Row>
                    </Container>


                </div>
            </Layout>
        )
    }


}

export default StateMapTemplate
